import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Drawer, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../../../components/logo';
import { NavSectionVertical } from '../../../components/nav-section';
import { useNavConfig } from '../../../components/nav-section/vertical/config-navigation';
import Scrollbar from '../../../components/scrollbar';
import { NAV } from '../../../config-global';
import useResponsive from '../../../hooks/useResponsive';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';
// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10%',
    padding: theme.spacing(2, 2.5),
}));
function NavVertical({ openNav, onCloseNav }) {
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'lg');
    const navConfig = useNavConfig();
    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const renderContent = (_jsxs(Scrollbar, { sx: {
            height: 1,
            '& .simplebar-content': {
                height: 1,
                display: 'flex',
                flexDirection: 'column',
            },
        }, children: [_jsxs(Stack, { spacing: 3, sx: {
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                }, children: [_jsx(StyledRoot, { children: _jsx(Logo, {}) }), _jsx(NavAccount, {})] }), _jsx(NavSectionVertical, { data: navConfig }), _jsx(Box, { sx: { flexGrow: 1 } })] }));
    return (_jsxs(Box, { component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD },
        }, children: [_jsx(NavToggleButton, {}), isDesktop ? (_jsx(Drawer, { open: true, variant: "permanent", PaperProps: {
                    sx: {
                        zIndex: 0,
                        width: NAV.W_DASHBOARD,
                        bgcolor: 'transparent',
                        borderRightStyle: 'dashed',
                    },
                }, children: renderContent })) : (_jsx(Drawer, { open: openNav, onClose: onCloseNav, ModalProps: {
                    keepMounted: true,
                }, PaperProps: {
                    sx: {
                        width: NAV.W_DASHBOARD,
                    },
                }, children: renderContent }))] }));
}
export default NavVertical;
